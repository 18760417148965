import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SignupService from "../services/SignupService";

export const sendCpf = createAsyncThunk(
  "signup/sendcpf",
  async (cpf, { rejectWithValue }) => {
    try {
      const { data } = await SignupService.checkCpfExistance(cpf);

      return { cpf: cpf, client: data[0] };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendStepOne = createAsyncThunk(
  "signup/sendstepone",
  async (formSubmit, { rejectWithValue, getState }) => {
    try {
      const { data } = await SignupService.sendStepOne(
        getState().signup?.code,
        formSubmit,
        getState().signup?.client
      );

      return { stepOneData: { ...formSubmit, completed: true }, client: data };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendStepTwo = createAsyncThunk(
  "signup/sendsteptwo",
  async (formSubmit, { rejectWithValue, getState }) => {
    try {
      const { data } = await SignupService.sendStepTwo(
        getState().signup?.code,
        formSubmit,
        getState().signup?.client
      );
      return { stepTwoData: { ...formSubmit, completed: true }, client: data };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendStepThree = createAsyncThunk(
  "signup/sendstepthree",
  async (formSubmit, { rejectWithValue, getState }) => {
    try {
      const { data } = await SignupService.sendStepThree(
        getState().signup?.code,
        formSubmit,
        getState().signup?.client
      );
      return {
        stepThreeData: { ...formSubmit, completed: true },
        client: data,
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendStepFour = createAsyncThunk(
  "signup/sendstepfour",
  async (formSubmit, { rejectWithValue, getState }) => {
    try {
      const { data } = await SignupService.sendStepFour(
        getState().signup?.code,
        formSubmit,
        getState().signup?.client
      );
      return {
        stepFourData: { ...formSubmit, completed: true },
        client: data,
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendStepFive = createAsyncThunk(
  "signup/sendstepfive",
  async (formSubmit, { rejectWithValue, getState }) => {
    try {
      await SignupService.checkIwmCpfExistance(getState().signup?.cpf);

      const { data } = await SignupService.finishAccountCreation(
        getState().signup?.code,
        getState().signup?.client
      );
      return { stepFiveData: formSubmit, clientId: data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendFeedback = createAsyncThunk(
  "signup/sendfeedback",
  async (formSubmit, { rejectWithValue, getState }) => {
    try {
      const { data } = await SignupService.sendFeedback(
        getState().signup?.clientId,
        getState().signup?.sms_check,
        formSubmit.message,
        formSubmit.rating
      );

      return {
        stepFiveData: formSubmit,
        clientId: data,
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

function loadState() {
  const sessionData = JSON.parse(sessionStorage.getItem("signup_data"));

  return {
    cpf: sessionData?.cpf || "",
    step1: sessionData?.step1 || {},
    step2: sessionData?.step2 || {},
    step3: sessionData?.step3 || {},
    step4: sessionData?.step4 || {},
    client: sessionData?.client || {},
    loading: false,
    clientId: sessionData?.clientId,
    nome: sessionData?.client?.nome || sessionData?.nome || "",
    sms_check: sessionData?.sms_check || false,
    code: sessionData?.code || "00000",
    isConsultor: sessionData?.isConsultor || false,
  };
}

function saveState(state) {
  sessionStorage.setItem("signup_data", JSON.stringify(state));
}

export const signupSlice = createSlice({
  name: "signup",
  initialState: loadState(),
  reducers: {
    storeCode: (state, { payload }) => {
      if (payload?.code) {
        state.code = payload.code;
      } else {
        state.code = "00000";
      }

      if (payload?.isConsultor) {
        state.isConsultor = payload.isConsultor;
      } else {
        state.isConsultor = false;
      }

      saveState(state);
    },
    reset: () => {
      saveState({});
      return {};
    },
  },
  extraReducers: {
    [sendCpf.pending]: (state) => {
      state.loading = true;
    },

    [sendCpf.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.cpf = payload.cpf;
      state.client = payload.client;
      saveState(state);
    },

    [sendCpf.rejected]: (state) => {
      state.loading = false;
    },

    [sendStepOne.pending]: (state) => {
      state.loading = true;
    },

    [sendStepOne.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.step1 = payload.stepOneData;
      state.client = payload.client;
      state.step1.nome = payload.client.nome;
      saveState(state);
    },

    [sendStepOne.rejected]: (state) => {
      state.loading = false;
    },

    [sendStepTwo.pending]: (state) => {
      state.loading = true;
    },

    [sendStepTwo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.step2 = payload.stepTwoData;
      state.client = payload.client;
      state.step2.nome_mae = payload.client.nomeMae;
      saveState(state);
    },

    [sendStepTwo.rejected]: (state) => {
      state.loading = false;
    },

    [sendStepThree.pending]: (state) => {
      state.loading = true;
    },

    [sendStepThree.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.step3 = payload.stepThreeData;
      state.client = payload.client;
      saveState(state);
    },

    [sendStepThree.rejected]: (state) => {
      state.loading = false;
    },

    [sendStepFour.pending]: (state) => {
      state.loading = true;
    },

    [sendStepFour.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.step4 = payload.stepFourData;
      state.client = payload.client;
      saveState(state);
    },

    [sendStepFour.rejected]: (state) => {
      state.loading = false;
    },

    [sendStepFive.pending]: (state) => {
      state.loading = true;
    },

    [sendStepFive.fulfilled]: (state, { payload }) => {
      const newState = {
        sms_check: payload.stepFiveData.sms_check,
        nome: state.step1.nome,
        clientId: payload.clientId,
        code: state.code,
        loading: false,
        isConsultor: state.isConsultor,
      };

      saveState(newState);
      return newState;
    },

    [sendStepFive.rejected]: (state, { payload }) => {
      const clientId = payload?.response?.data?.message;

      if (clientId) {
        const newState = {
          sms_check: 1,
          nome: state.step1.nome,
          clientId: clientId,
          code: state.code,
          loading: false,
          isConsultor: state.isConsultor,
        };
        saveState(newState);
        return newState;
      }
      saveState({ ...state, loading: false });
      return { ...state,  loading: false };
    },

    [sendFeedback.pending]: (state) => {
      state.loading = true;
    },

    [sendFeedback.fulfilled]: () => {
      const state = {
        loading: false,
      };
      saveState(state);
      return state;
    },

    [sendFeedback.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectData = (state) => state.signup;
export const selectLoading = (state) => state.signup.loading;

export const {
  reset: resetSignup,
  storeCode,
  setClientId,
} = signupSlice.actions;
export default signupSlice.reducer;
