import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import { sendStepOne, selectData } from "../../store/SignupSlice";
import { useSnackbar } from "../../utils/Hooks";

import { FormLayout } from "../../components/Layouts";
import { ChatBubble, CodeMessage } from "../../components/MiscComponents";
import {
  BirthdayField,
  CellphoneField,
  CpfField,
  NameField,
  EmailField,
  AsyncSubmitButton,
  stepOneSchema
} from "../../components/FormComponents";

// Texts
const buttonText = "PRÓXIMA ETAPA";
const chatBubbleText =
  "Olá! Primeiramente precisamos de alguns dados sobre você para prosseguirmos com o seu cadastro. É rapidinho!";
const serverErrorText = "Ocorreu algum erro no servidor!";

const StepOne = ({ onNext }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectData);
  const { showSnackbar } = useSnackbar();

  const { control, errors, handleSubmit, register } = useForm({
    defaultValues: {
      celular: data?.step1?.celular || "",
      cpf: data?.cpf || "",
      data_nascimento: data?.step1?.data_nascimento || "",
      nome: data?.step1?.nome || "",
      email: data?.step1?.email || "",
    },
    mode: "onSubmit",
    resolver: yupResolver(stepOneSchema),
  });

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(sendStepOne(formData));

      if (sendStepOne.fulfilled.match(result)) {
        onNext();
      } else {
        showSnackbar(serverErrorText, "error");
      }
    },
    [dispatch, onNext, showSnackbar]
  );

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <ChatBubble>{chatBubbleText}</ChatBubble>
      </Grid>

      <Grid item xs={12}>
        <CpfField control={control} errors={errors} disabled />
      </Grid>

      <Grid item xs={12}>
        <NameField errors={errors} register={register} />
      </Grid>

      <Grid item xs={12}>
        <EmailField errors={errors} register={register}/>
      </Grid>

      <Grid item xs={12}>
        <BirthdayField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <CellphoneField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <AsyncSubmitButton>{buttonText}</AsyncSubmitButton>
      </Grid>

      {data?.isConsultor && <Grid item xs={12}>
        <CodeMessage code={data?.code}/>
      </Grid>}
    </FormLayout>
  );
};

export default StepOne;
