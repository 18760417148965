import StepFive from "../pages/steps/StepFive";
import StepFour from "../pages/steps/StepFour";
import StepOne from "../pages/steps/StepOne";
import StepThree from "../pages/steps/StepThree";
import StepTwo from "../pages/steps/StepTwo";

// States
export const states = [
  { value: "AC", label: "Acre (AC)" },
  { value: "AL", label: "Alagoas (AL)" },
  { value: "AP", label: "Amapá (AP)" },
  { value: "AM", label: "Amazonas (AM)" },
  { value: "BA", label: "Bahia (BA)" },
  { value: "CE", label: "Ceará (CE)" },
  { value: "DF", label: "Distrito Federal (DF)" },
  { value: "ES", label: "Espírito Santo (ES)" },
  { value: "GO", label: "Goiás (GO)" },
  { value: "MA", label: "Maranhão (MA)" },
  { value: "MT", label: "Mato Grosso (MT)" },
  { value: "MS", label: "Mato Grosso do Sul (MS)" },
  { value: "MG", label: "Minas Gerais (MG)" },
  { value: "PA", label: "Pará (PA)" },
  { value: "PB", label: "Paraíba (PB)" },
  { value: "PR", label: "Paraná (PR)" },
  { value: "PE", label: "Pernambuco (PE)" },
  { value: "PI", label: "Piauí (PI)" },
  { value: "RJ", label: "Rio de Janeiro (RJ)" },
  { value: "RN", label: "Rio Grande do Norte (RN)" },
  { value: "RS", label: "Rio Grande do Sul (RS)" },
  { value: "RO", label: "Rondônia (RO)" },
  { value: "RR", label: "Roraima (RR)" },
  { value: "SC", label: "Santa Catarina (SC)" },
  { value: "SP", label: "São Paulo (SP)" },
  { value: "SE", label: "Sergipe (SE)" },
  { value: "TO", label: "Tocantins (TO)" },
];

// Steps
export const steps = {
  1: { url: "/cadastro-pessoal-1", Component: StepOne },
  2: { url: "/documentos-2", Component: StepTwo },
  3: { url: "/adicional-3", Component: StepThree },
  4: { url: "/endereco-4", Component: StepFour },
  5: { url: "/confirmacao-5", Component: StepFive },
};
// Urls
const finish = "/smsinfor";
const home = "/";
const homeWithCode = "/cliente/";
const homeConsultor = "/consultor/";
const newAccount = "";

export const urls = {
  finish: finish,
  home: home,
  homeWithCode: homeWithCode,
  homeConsultor: homeConsultor,
  newAccount: newAccount,
  steps: {
    1: steps[1].url,
    2: steps[2].url,
    3: steps[3].url,
    4: steps[4].url,
    5: steps[5].url,
  },
};

export const numOfSteps = Object.keys(urls.steps).length;
