import { BrowserRouter, Route, Switch } from "react-router-dom";

import { urls } from "../utils/Constants";

import Finish from "../pages/Finish";
import Home from "../pages/Home";
import NewAccount from "../pages/NewAccount";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={urls.home} component={() => <Home />} />
        <Route path={`${urls.homeWithCode}:code`} component={() => <Home />} />
        <Route path={`${urls.homeConsultor}:code`} component={() => <Home isConsultor />} />
        <Route exact path={urls.finish} component={() => <Finish />} />
        <Route path={"/:step"} component={() => <NewAccount />} />

        <Route path="*" component={() => <h1>Not found</h1>} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
