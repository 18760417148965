import { useCallback, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Box, makeStyles, Slide } from "@material-ui/core";

import { urls, numOfSteps, steps } from "../utils/Constants";
import { selectData } from "../store/SignupSlice";

import { FullscreenLayout } from "../components/Layouts";
import { BackButton, SecureEnv } from "../components/MiscComponents";
import Redirecter from "../components/Redirecter";
import FormStepper from "../components/FormStepper";

import { useIsMobile } from "../utils/Hooks";


function getStepContent(stepIndex, handleNext) {
  const curStep = steps[stepIndex];
  return <curStep.Component onNext={handleNext}/>;
}

const NewAccount = () => {
  const classes = useStyles();
  const history = useHistory();
  const data = useSelector(selectData);
  const isMobile = useIsMobile();

  const { step } = useParams();
  const stepNum = useMemo(() => parseInt(step.charAt(step.length - 1)), [step]);

  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState("left");

  const handleNext = useCallback(() => {
    if (stepNum < numOfSteps) {
      setSlideIn(false);
      setSlideDirection("right");

      setTimeout(() => {
        history.push(urls.steps[stepNum + 1]);
        setSlideIn(true);
        setSlideDirection("left");
      }, 300);
    } else if (stepNum === numOfSteps) {
      history.push(urls.finish);
    }
  }, [history, stepNum]);

  const handleBack = useCallback(() => {
    if (stepNum === 1 && data?.isConsultor) {
      history.push(urls.homeConsultor + data?.code);
      return;
    }

    if (stepNum === 1) {
      history.push(urls.homeWithCode + data?.code);
    } else if (stepNum > 1) {
      setSlideIn(false);
      setSlideDirection("left");

      setTimeout(() => {
        history.push(urls.steps[stepNum - 1]);
        setSlideIn(true);
        setSlideDirection("right");
      }, 300);
    }
  }, [history, stepNum, data?.code]);

  return (
    <Redirecter step={stepNum}>
      <FullscreenLayout style={{ overflowX: "hidden" }}>
        <Box
          alignItems="center"
          className={classes.header}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          order={isMobile ? 0 : 1}
        >
          {isMobile && (
            <Box display="flex" justifyContent="flex-start" width="100%">
              <BackButton onClick={handleBack} isMobile />
            </Box>
          )}
          <FormStepper activeStep={stepNum - 1} numOfSteps={numOfSteps} />
        </Box>

        <SecureEnv order={isMobile ? 1 : 0} />

        <Slide
          direction={slideDirection}
          in={slideIn}
          timeout={300}
          style={{ order: 2 }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: isMobile ? "100%" : "800px" }}>
              {!isMobile && (
                <Box display="flex" alignItems="center" padding="24px">
                  <BackButton onClick={handleBack} />
                </Box>
              )}
              {getStepContent(stepNum, handleNext)}
            </div>
          </div>
        </Slide>
      </FullscreenLayout>
    </Redirecter>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    background:
      "linear-gradient(90deg, rgba(243,108,1,1) 0%, rgba(255,133,22,1) 100%)",
    padding: theme.spacing(5),
  },
  stepper: {
    background: "transparent",
  },
}));

export default NewAccount;
