import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import { selectData, sendStepThree } from "../../store/SignupSlice";
import { useSnackbar } from "../../utils/Hooks";

import { FormLayout } from "../../components/Layouts";
import { ChatBubble, CodeMessage } from "../../components/MiscComponents";
import {
  CidadeNascimentoField,
  SexoField,
  EstadoNascimentoField,
  AsyncSubmitButton,
  stepThreeSchema
} from "../../components/FormComponents";

// Texts
const buttonText = "PRÓXIMA ETAPA";
const chatBubbleText =
  "Precisamos também de mais alguns dados sobre você e seu local de nascimento:";
const serverErrorText = "Ocorreu algum erro no servidor!";

const StepThree = ({ onNext }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectData);
  const { showSnackbar } = useSnackbar();

  const { control, errors, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      cidade_nascimento: data?.step3?.cidade_nascimento || "",
      estado_nascimento: data?.step3?.estado_nascimento || "",
      sexo: data?.step3?.sexo || "",
    },
    mode: "onSubmit",
    resolver: yupResolver(stepThreeSchema),
  });

  const wBirthstate = watch(["estado_nascimento"]);

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(sendStepThree(formData));

      if (sendStepThree.fulfilled.match(result)) {
        onNext();
      } else {
        showSnackbar(serverErrorText, "error");
      }
    },
    [dispatch, onNext, showSnackbar]
  );

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <ChatBubble>{chatBubbleText}</ChatBubble>
      </Grid>

      <Grid item xs={12}>
        <SexoField errors={errors} register={register} />
      </Grid>

      <Grid item xs={12}>
        <EstadoNascimentoField
          errors={errors}
          register={register}
          onChange={() => {
            setValue("cidade_nascimento", "");
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <CidadeNascimentoField
          control={control}
          errors={errors}
          estado={wBirthstate?.estado_nascimento}
        />
      </Grid>

      <Grid item xs={12}>
        <AsyncSubmitButton>{buttonText}</AsyncSubmitButton>
      </Grid>

      {data?.isConsultor && <Grid item xs={12}>
        <CodeMessage code={data?.code}/>
      </Grid>}
    </FormLayout>
  );
};

export default StepThree;
