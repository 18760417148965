export function isValidBirthday(birthday) {
  let splitBirthday = birthday.split("/");
  const day = splitBirthday[0];
  const month = splitBirthday[1];
  const year = splitBirthday[2];

  let currentYear = new Date().getFullYear();

  if (!year || !day || !month) {
    return false;
  }

  if (day > 31 || day < 1) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  if (year < 1900 || year > currentYear) {
    return false;
  }

  return true;
}

export function isOlderThan18(birthday) {
  let splitBirthday = birthday.split("/");
  const day = splitBirthday[0];
  const month = splitBirthday[1];
  const year = splitBirthday[2];

  const age = calculateAge(new Date(`${year}-${month}-${day}`));

  if (age < 18) {
    return false;
  }

  return true;
}

function calculateAge(birthday) {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const isValidCnh = (cnh) => {
  var char1 = cnh.charAt(0);

  if (cnh.replace(/[^\d]/g, "").length !== 11 || char1.repeat(11) === cnh) {
    return false;
  }

  for (var i = 0, j = 9, v = 0; i < 9; ++i, --j) {
    v += +(cnh.charAt(i) * j);
  }

  var dsc = 0,
    vl1 = v % 11;

  if (vl1 >= 10) {
    vl1 = 0;
    dsc = 2;
  }

  for (i = 0, j = 1, v = 0; i < 9; ++i, ++j) {
    v += +(cnh.charAt(i) * j);
  }

  var x = v % 11;
  var vl2 = x >= 10 ? 0 : x - dsc;

  return "" + vl1 + vl2 === cnh.substr(-2);
};

export const isValidCpf = (CPF) => {
  // pega o valor cpf digitado

  if (CPF) {
    // se existir cpf
    CPF = CPF.replace(".", "");
    CPF = CPF.replace(".", "");
    CPF = CPF.replace("-", "");

    let numbers;
    let digits;
    let sum;
    let i;
    let result;
    let equalDigits = 1;

    if (CPF.length < 11) {
      // se tem 11 digitos
      return false;
    } else {
      for (i = 0; i < CPF.length - 1; i++) {
        // buscar digitos iguais
        if (CPF.charAt(i) !== CPF.charAt(i + 1)) {
          equalDigits = 0;
          break;
        }
      }

      if (!equalDigits) {
        numbers = CPF.substring(0, 9);
        digits = CPF.substring(9);

        sum = 0;
        for (i = 10; i > 1; i--) {
          sum += numbers.charAt(10 - i) * i;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(0))) {
          // primeiro digito validador
          return false;
        } else {
          numbers = CPF.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            // segundo digito  validador
            return false;
          } else {
            return true;
          }
        }
      } else {
        return false; // caso 1111111111 ou 2222222222
      }
    }
  } else {
    return false;
  }
};
