import { useCallback } from "react";

import { useMediaQuery } from "@material-ui/core";
import { useSnackbar as srcUseSnackbar } from "notistack";

export const useIsMobile = () => {
  return useMediaQuery((theme) => theme.breakpoints.down("sm"));
};

export const useIsSmallScreen = () => {
  return useMediaQuery((theme) => theme.breakpoints.down("md"));
};

export const useSnackbar = () => {
  const snackbar = srcUseSnackbar();

  const showSnackbar = useCallback(
    (message, type) => {
      snackbar.enqueueSnackbar(message, {
        variant: type,
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
    },
    [snackbar]
  );

  return { ...snackbar, showSnackbar };
};
