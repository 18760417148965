import { useEffect, useState } from "react";

import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectData } from "../store/SignupSlice";
import { urls } from "../utils/Constants";

const Redirecter = ({ children, step }) => {
  const data = useSelector(selectData);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (step) {
      // Redirect from feedback page
      if (step === urls.finish && !data?.clientId) {
        setRedirect(true);
        return;
      }

      // Redirect from first step
      if (step === 1 && !data?.cpf) {
        setRedirect(true);
        return;
      }

      // Redirect from any other step
      for (let i = 1; i < step; i++) {
        if (!data?.[`step${i}`]?.completed) {
          setRedirect(true);
          return;
        }
      }
    } else {
      setRedirect(true);
    }
  }, [step]);

  return redirect ? <Redirect to={urls.home} /> : <div>{children}</div>;
};

export default Redirecter;
