import { createMuiTheme } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

// Color constants
const backgroundDefault = "#F5F5F5";

const primary = "#FD973C";
const secondary = "#412E6E";

const textPrimary = "#FFF";
const textSecondary = "#000";

export default createMuiTheme({
  palette: {
    background: {
      default: backgroundDefault,
    },
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif, Arial",
    fontWeightLight: 200,
  },
  spacing: 4,
  ptBR,
});
