import clsx from "clsx";

import { Box, Container, Grid, makeStyles } from "@material-ui/core";

export const FullscreenLayout = ({ children, className, ...rest }) => {
  const classes = useStylesFullscreenLayout();

  return (
    <Container
      disableGutters
      maxWidth={false}
      className={clsx(classes.container, className)}
      {...rest}
    >
      {children}
    </Container>
  );
};

const useStylesFullscreenLayout = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minHeight: "100vh",
    height: "100vh",
    width: "100%",
  },
}));

export const FormLayout = ({
  children,
  gridProps,
  onSubmit,
  spacing = 5,
  ...rest
}) => {
  return (
    <Box
      alignItems="center"
      component="form"
      display="flex"
      flexDirection="column"
      justifyContent="start"
      onSubmit={onSubmit}
      padding="24px"
      width="100%"
      noValidate={true}
      {...rest}
    >
      <Grid container spacing={spacing} {...gridProps}>
        {children}
      </Grid>
    </Box>
  );
};
