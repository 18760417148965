import { useCallback, useState } from "react";

import {
  Box,
  Dialog,
  makeStyles,
  Typography,
  DialogContent,
} from "@material-ui/core";

import { Contacts as ContactsIcon } from "@material-ui/icons";

import { FormButton, FormCircleButton } from "./FormComponents";
import { useIsMobile } from "../utils/Hooks";

const InfoExampleDialog = ({ onClose, open }) => {
  const classes = useStyles();

  const [activeBtn, setActiveBtn] = useState(0);

  const handleSelectRg = useCallback(() => {
    if (activeBtn === 1) {
      setActiveBtn(0);
    }
  }, [activeBtn]);

  const handleSelectCnh = useCallback(() => {
    if (activeBtn === 0) {
      setActiveBtn(1);
    }
  }, [activeBtn]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{ paper: classes.paper }}
      scroll="paper"
    >
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Box display="flex" justifyContent="center">
            <FormCircleButton
              active={activeBtn === 0}
              icon={<ContactsIcon />}
              label="RG"
              onClick={handleSelectRg}
            />

            <FormCircleButton
              active={activeBtn === 1}
              icon={<ContactsIcon />}
              label="CNH"
              onClick={handleSelectCnh}
            />
          </Box>

          {activeBtn === 0 && <RgExample />}
          {activeBtn === 1 && <CnhExample />}
        </Box>
      </DialogContent>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="12px"
      >
        <FormButton onClick={onClose} height={48}>
          FECHAR
        </FormButton>
      </Box>
    </Dialog>
  );
};

const RgExample = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection={isMobile ? "column" : "row"}
      height="240px"
      marginTop="24px"
    >
      <Box>
        <Typography
          variant="body1"
          color="secondary"
          align="center"
          style={{
            fontWeight: "bold",
            marginBottom: !isMobile ? "50px" : "4px",
          }}
        >
          Frente
        </Typography>

        <Box
          style={{
            transform: !isMobile ? "rotate(270deg)" : null,
          }}
        >
          <img
            src="/images/rg_frente.jpg"
            alt="rg"
            style={{
              width: "270px",
            }}
          />
        </Box>
      </Box>

      <hr className={classes.divider} />

      <Box>
        <Typography
          variant="body1"
          color="secondary"
          align="center"
          style={{
            fontWeight: "bold",
            marginBottom: !isMobile ? "50px" : "4px",
          }}
        >
          Verso
        </Typography>

        <img
          src="/images/rg_costas.jpg"
          alt="rg"
          style={{
            width: "270px",
            transform: !isMobile ? "rotate(270deg)" : null,
          }}
        />
      </Box>
    </Box>
  );
};

const CnhExample = () => {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      height="240px"
      marginTop="16px"
    >
      <Typography
        variant="body1"
        color="secondary"
        align="center"
        style={{ fontWeight: "bold", marginBottom: "4px" }}
      >
        Documento aberto
      </Typography>

      <img src="/images/cnh_aberto.jpg" alt="rg" style={{ width: "270px" }} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "95vh",
    maxHeight: "700px",
    borderRadius: "30px",
  },
  divider: {
    backgroundColor: "#412E6E",
    height: "10px",
    orientation: "horizontal",
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    paper: {
      height: "auto",
      minHeight: "65vh",
    },
    divider: {
      marginTop: theme.spacing(20),
      height: "100%",
      orientation: "vertical",
      width: "3px",
    },
  },
}));

export default InfoExampleDialog;
