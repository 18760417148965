import React, { useState } from "react";

import {
  Box,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
  Slide,
} from "@material-ui/core";

import { useIsMobile } from "../utils/Hooks";

import CloseIcon from "@material-ui/icons/Close";

import ReactPlayer from "react-player";
import ConfirmationDialog from "./ConfirmationDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VideoDialog = ({ confirmation, onClose, open }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [confirmationOpen, setConfirmationOpen] = useState(confirmation);
  const [confirmed, setConfirmed] = useState(
    JSON.parse(sessionStorage.getItem("signup_data")).hasConfirmedVideo
  );

  if (confirmation && !confirmed) {
    return (
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => {
          setConfirmationOpen(false);
        }}
        onConfirm={() => {
          setConfirmed(true);
          sessionStorage.setItem(
            "signup_data",
            JSON.stringify({
              ...JSON.parse(sessionStorage.getItem("signup_data")),
              hasConfirmedVideo: true,
            })
          );
        }}
      />
    );
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen={isMobile ? true : false}
      classes={{ paper: classes.paper }}
      scroll="paper"
      disableBackdropClick
      TransitionComponent={Transition}
    >
      <Box className={classes.header}>
        <Typography className={classes.title}>
          Como formalizar sua conta
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>
      <ReactPlayer
        url="/videos/bmg-video.mp4"
        className={classes.videoplayer}
        controls
        width={isMobile ? "100%" : "auto"}
        height={isMobile ? "calc(100% - 50px)" : "700px"}
        volume={1}
        playing
        muted={false}
        preload="auto"
      />
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "0px",
    overflow: "hidden",
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  closeButton: {},
  closeIcon: {
    color: "white",
    fontSize: "1.5rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: theme.palette.secondary.main,
    height: "50px",
    padding: "0px 8px 0px 12px",
  },
  videoplayer: {
    background: "linear-gradient(180deg, #ff7907 0%, #ff5707 100%)",
    "& video": {
      background: "linear-gradient(180deg, #ff7907 0%, #ff5707 100%)",
    },
    "& video:focus": {
      outline: "none",
    },
  },
  [theme.breakpoints.up("md")]: {
    paper: {
      borderRadius: "30px",
    },
  },
}));

export default VideoDialog;
