import { useMemo } from "react";
import { Controller } from "react-hook-form";
import { getStateCities } from "easy-location-br";
import InputMask from "react-input-mask";
import clsx from "clsx";
import * as yup from "yup";

import {
  Button,
  TextField,
  makeStyles,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { states } from "../utils/Constants";

import {
  isValidBirthday,
  isOlderThan18,
  isValidCnh,
  isValidCpf,
} from "../utils/Validators";
import { validateCep, validatePhone, validateEmail } from "validations-br";
import { useSelector } from "react-redux";
import { selectLoading } from "../store/SignupSlice";

export const ControlledMaskedField = ({
  control,
  customOnBlur,
  defaultValue,
  disabled,
  helperText,
  inputMaskProps,
  inputProps,
  label,
  mask,
  name,
  rules,
  isNumeric,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({ onBlur, ...renderProps }) => {
        return (
          <InputMask
            disabled={disabled}
            mask={mask}
            maskChar={""}
            onBlur={(e) => {
              onBlur(e);
              if (customOnBlur) {
                customOnBlur(e);
              }
            }}
            {...renderProps}
            {...inputMaskProps}
          >
            {() => (
              <FormField
                disabled={disabled}
                helperText={helperText}
                label={label}
                isNumeric={isNumeric}
                {...inputProps}
              />
            )}
          </InputMask>
        );
      }}
      {...rest}
    />
  );
};

export const FormField = ({
  FormHelperTextProps,
  label,
  height = 56,
  helperText,
  InputLabelProps,
  InputProps,
  loading,
  SelectProps,
  shrink,
  uppercase = true,
  isNumeric,
  type = "text",
  ...rest
}) => {
  const classes = useStylesFormField(height);

  return (
    <TextField
      fullWidth
      label={label}
      helperText={helperText}
      variant="filled"
      FormHelperTextProps={{
        className: classes.helperText,
        ...FormHelperTextProps,
      }}
      inputProps={{
        inputMode: isNumeric ? "numeric" : type,
        pattern: isNumeric ? "[0-9]*" : ".*",
      }}
      InputLabelProps={{
        classes: {
          focused: classes.focusedLabel,
          root: classes.label,
          filled: classes.focusedLabel,
        },
        shrink: shrink,
        ...InputLabelProps,
      }}
      InputProps={{
        classes: {
          root: clsx(classes.root, classes.formHeight),
          input: clsx(classes.input, { [classes.inputUppercase]: uppercase }),
          focused: classes.focused,
        },
        disableUnderline: true,
        endAdornment: loading ? <CircularProgress color="primary" /> : null,
        ...InputProps,
      }}
      SelectProps={{
        disableUnderline: true,
        native: true,
        classes: {
          select: classes.select,
        },
        ...SelectProps,
      }}
      {...rest}
    />
  );
};

const useStylesFormField = makeStyles((theme) => ({
  focused: {},
  focusedLabel: {},
  helperText: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  input: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(3),

    "&::placeholder": {
      textTransform: "none",
    },
  },
  inputUppercase: {
    textTransform: "uppercase",
  },
  label: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0, 3),
    "&$focusedLabel": {
      color: theme.palette.secondary.main,
    },
  },
  root: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "30px",
    boxShadow: theme.shadows[2],
    overflow: "hidden",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    WebkitBoxShadow: theme.shadows[2],
    "&$focused": {
      border: `2px solid ${theme.palette.secondary.main}`,
      backgroundColor: "transparent",
      borderColor: theme.palette.secondary.main,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.MuiFilledInput-root.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  formHeight: (h = 55) => ({
    height: h,
  }),
  select: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}));

export const FormButton = ({ children, className, height = 56, ...rest }) => {
  const classes = useStylesFormButton(height);

  return (
    <Button
      className={clsx(classes.button, className)}
      color="secondary"
      fullWidth
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
};

const useStylesFormButton = makeStyles((theme) => ({
  button: (height) => ({
    borderRadius: "30px",
    fontWeight: "bold",
    fontSize: "16px",
    height: `${height}px`,
  }),
}));

export const FormCircleButton = ({ icon, label, active, ...rest }) => {
  const classes = useStylesFormCircleButton();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <IconButton
        className={clsx(classes.circleButton, { [classes.active]: active })}
        {...rest}
      >
        {icon}
      </IconButton>

      <Typography variant="body1" style={{ color: "black", marginTop: "4px" }}>
        {label}
      </Typography>
    </Box>
  );
};

const useStylesFormCircleButton = makeStyles((theme) => ({
  circleButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: "transparent",
    color: theme.palette.secondary.main,
    width: "64px",
    height: "64px",
    boxShadow: theme.shadows[2],
    margin: theme.spacing(0, 5),
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: "white",
    },
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export const FormIconButton = ({
  icon,
  label,
  active,
  activeStyle,
  ...rest
}) => {
  const classes = useStylesFormIconButton();

  return (
    <IconButton
      className={clsx(classes.circleButton, { [activeStyle]: active })}
      style={active ? { ...activeStyle } : {}}
      {...rest}
    >
      {icon}
    </IconButton>
  );
};

const useStylesFormIconButton = makeStyles((theme) => ({
  circleButton: {
    backgroundColor: "transparent",
    color: theme.palette.secondary.main,
    margin: theme.spacing(0, 1),
  },
}));

export const FormAutoselect = ({
  defaultValue,
  disabled,
  label,
  name,
  control,
  options,
  autocompleteProps,
}) => {
  const classes = useStylesFormAutoselect();

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={(props) => (
        <Autocomplete
          {...props}
          className={classes.autocomplete}
          classes={{
            paper: classes.paper,
          }}
          disabled={disabled}
          options={options}
          noOptionsText="Sem opções"
          onChange={(_, data) => props.onChange(data)}
          getOptionSelected={(option, value) => option === value}
          renderInput={(params) => <FormField {...params} label={label} />}
          {...autocompleteProps}
        />
      )}
    />
  );
};

const useStylesFormAutoselect = makeStyles((theme) => ({
  paper: {
    color: theme.palette.text.secondary,
  },
}));

export const CityFormField = ({
  control,
  defaultValue,
  errors,
  estado,
  label,
  name,
  ...rest
}) => {
  let cities = useMemo(() => getStateCities(estado)?.map((city) => city.name), [
    estado,
  ]);

  return (
    <FormAutoselect
      control={control}
      name={name}
      label={label}
      options={cities}
      {...rest}
    />
  );
};

/* READY TO USE COMPONENTS */

export const CpfField = ({ control, errors, ...rest }) => {
  return (
    <ControlledMaskedField
      control={control}
      helperText={errors?.cpf?.message}
      label="CPF*"
      mask="999.999.999-99"
      name={"cpf"}
      isNumeric
      {...rest}
    />
  );
};

CpfField.fieldName = "cpf";
CpfField.schema = yup
  .string()
  .required("O cpf é obrigatório")
  .test("cpf_test", "O cpf é inválido", (cpf) => isValidCpf(cpf));

export const NameField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.nome?.message}
      inputRef={register}
      label="NOME COMPLETO*"
      name="nome"
      placeholder="Sem Abreviação"
      {...rest}
    />
  );
};

NameField.fieldName = "nome";
NameField.schema = yup
  .string()
  .required("O campo nome é obrigatório")
  .max(150, "O nome deve ter menos de 150 caracteres")
  .min(5, "O nome deve ter mais de 4 caracteres")
  .test("nome_test", "O nome é inválido ou incompleto", (nome) =>
    /^[a-zA-ZáéíóúâêîôûãõàäëïöüÁÉÍÓÚÂÊÎÌÒÙÈÔÛÀÃÕÄËÏÖÜçÇ\sèñÈÑ-]+(?:\s[a-zA-ZáéíóúâêîôûãõàäëïöüÁÉÍÓÚÂÊÎÌÒÙÈÔÛÀÃÕÄËÏÖÜçÇ\sèñÈÑ-]+)+$/.test(
      nome
    )
  );

export const SexoField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.sexo?.message}
      inputRef={register}
      label="SEXO*"
      name="sexo"
      select
      {...rest}
    >
      {["Masculino", "Feminino", "Não Informado"].map((item) => (
        <option key={item}>{item}</option>
      ))}
    </FormField>
  );
};

SexoField.fieldName = "sexo";
SexoField.schema = yup.string().required("O campo sexo é obrigatório");

export const BirthdayField = ({ control, errors, ...rest }) => {
  return (
    <ControlledMaskedField
      control={control}
      helperText={errors?.data_nascimento?.message}
      label="DATA DE NASCIMENTO*"
      mask="99/99/9999"
      name="data_nascimento"
      isNumeric
      {...rest}
    />
  );
};

BirthdayField.fieldName = "data_nascimento";
BirthdayField.schema = yup
  .string()
  .required("A data de nascimento é obrigatória")
  .test("birhtday_test", "Data de nascimento inválida", (birthday) =>
    isValidBirthday(birthday)
  )
  .test("olderthan18_test", "Você deve ser maior de 18 anos", (birthday) =>
    isOlderThan18(birthday)
  );

export const CellphoneField = ({ control, errors, ...rest }) => {
  return (
    <ControlledMaskedField
      control={control}
      helperText={errors?.celular?.message}
      label="CELULAR COM DDD*"
      mask="(99) 99999-9999"
      name="celular"
      isNumeric
      {...rest}
    />
  );
};

CellphoneField.fieldName = "celular";
CellphoneField.schema = yup
  .string()
  .required("O campo celular é obrigatório")
  .test("celular_test", "Número inválido", (celular) => validatePhone(celular));

export const RgField = ({ control, errors, ...rest }) => {
  return (
    <ControlledMaskedField
      control={control}
      helperText={errors?.rg?.message}
      label="NÚMERO DO RG*"
      mask="**************"
      name="rg"
      {...rest}
    />
  );
};

RgField.fieldName = "rg";
RgField.schema = yup
  .string()
  .required("O campo RG é obrigatório")
  .min(5, "O RG deve ter pelo menos 5 números");

export const CnhField = ({ control, errors, ...rest }) => {
  return (
    <ControlledMaskedField
      control={control}
      helperText={errors?.cnh?.message}
      label="NÚMERO DO CNH*"
      mask="99999999999"
      name="cnh"
      {...rest}
    />
  );
};

CnhField.fieldName = "cnh";
CnhField.schema = yup
  .string()
  .required("O campo CNH é obrigatório")
  .test("cnh_test", "O CNH não é válido", (cnh) => isValidCnh(cnh));

export const NomeMaeField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.nome_mae?.message}
      inputRef={register}
      label="NOME DA MÃE*"
      name="nome_mae"
      {...rest}
    />
  );
};

NomeMaeField.fieldName = "nome_mae";
NomeMaeField.schema = yup
  .string()
  .required("O campo Nome da Mãe é obrigatório")
  .max(100, "O nome da mãe deve ter menos de 100 caracteres")
  .min(5, "O nome da mãe deve ter mais de 4 caracteres")
  .test("nome_mae_test", "O nome da mãe está inválido ou incompleto", (nome) =>
    /^[a-zA-ZáéíóúâêîôûãõàäëïöüçÁÂÀÃÄÉÊËÍÎÏÓÔÕÖÚÛÜÇ\s-]+(?:\s[a-zA-ZáéíóúâêîôûãõàäëïöüçÁÉÍÓÚÂÊÎÔÛÀÃÕÄËÏÖÜÇ\s-]+)+$/.test(
      nome
    )
  );

export const CepField = ({ control, errors, ...rest }) => {
  return (
    <ControlledMaskedField
      control={control}
      helperText={errors?.cep?.message}
      label="CEP*"
      mask="99999-999"
      name="cep"
      isNumeric
      {...rest}
    />
  );
};

CepField.fieldName = "cep";
CepField.schema = yup
  .string()
  .required("O campo CEP é obrigatório")
  .test("cep_test", "O CEP não é válido", (cep) => validateCep(cep));

export const RuaField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.rua?.message}
      inputRef={register}
      label="LOGRADOURO*"
      placeholder="Rua, Avenida etc"
      name="rua"
      {...rest}
    />
  );
};

RuaField.fieldName = "rua";
RuaField.schema = yup
  .string()
  .required("O campo rua é obrigatório")
  .max(100, "A rua deve ter menos de 100 caracteres");

export const NumeroField = ({ control, errors, ...rest }) => {
  return (
    <ControlledMaskedField
      control={control}
      helperText={errors?.numero?.message}
      label="NÚMERO*"
      mask="9999999"
      name="numero"
      isNumeric
      {...rest}
    />
  );
};

NumeroField.fieldName = "numero";
NumeroField.schema = yup
  .number()
  .required("O campo número é obrigatório")
  .max(9999999, "O número deve ter no máximo 7 caracteres")
  .typeError("Deve ser fornecido um número");

export const ComplementoField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.complemento?.message}
      inputRef={register}
      label="COMPLEMENTO"
      name="complemento"
      {...rest}
    />
  );
};

ComplementoField.fieldName = "complemento";
ComplementoField.schema = yup
  .string()
  .max(10, "O complemento deve ter até 10 caracteres");

export const BairroField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.bairro?.message}
      inputRef={register}
      label="BAIRRO*"
      name="bairro"
      {...rest}
    />
  );
};

BairroField.fieldName = "bairro";
BairroField.schema = yup
  .string()
  .required("O campo bairro é obrigatório")
  .max(100, "O bairro deve ter menos de 100 caracteres");

export const CidadeField = ({ control, errors, estado, ...rest }) => {
  return (
    <CityFormField
      control={control}
      errors={errors}
      estado={estado}
      label="CIDADE*"
      name="cidade"
      {...rest}
    />
  );
};

CidadeField.fieldName = "cidade";
CidadeField.schema = yup
  .string()
  .required("O campo cidade é obrigatório")
  .max(100, "A cidade deve ter menos de 100 caracteres");

export const EstadoField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.estado?.message}
      inputRef={register}
      label="ESTADO*"
      name="estado"
      select
      {...rest}
    >
      {states.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </FormField>
  );
};

EstadoField.fieldName = "estado";
EstadoField.schema = yup.string().required("O campo estado é obrigatório");

export const MessageField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.message?.message}
      height={100}
      inputRef={register}
      multiline
      label="Deixe sua mensagem aqui..."
      name="message"
      rows={3}
      uppercase={false}
      {...rest}
    />
  );
};

MessageField.fieldName = "message";
MessageField.schema = yup.string();

export const EstadoNascimentoField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.estado_nascimento?.message}
      inputRef={register}
      label="ESTADO DE NASCIMENTO*"
      name="estado_nascimento"
      select
      {...rest}
    >
      {states.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </FormField>
  );
};

EstadoNascimentoField.fieldName = "estado_nascimento";
EstadoNascimentoField.schema = yup
  .string()
  .required("O campo estado de nascimento é obrigatório");

export const CidadeNascimentoField = ({ control, errors, estado, ...rest }) => {
  return (
    <CityFormField
      control={control}
      errors={errors}
      estado={estado}
      label="CIDADE DE NASCIMENTO*"
      name="cidade_nascimento"
      {...rest}
    />
  );
};

CidadeNascimentoField.fieldName = "cidade_nascimento";
CidadeNascimentoField.schema = yup
  .string()
  .required("O campo cidade de nascimento é obrigatório")
  .max(100, "A cidade deve ter menos de 100 caracteres");

export const SubmitButton = ({ children, ...rest }) => {
  return (
    <FormButton type="submit" {...rest}>
      {children}
    </FormButton>
  );
};

export const AsyncSubmitButton = ({ children, disabled, ...rest }) => {
  const isLoading = useSelector(selectLoading);

  return (
    <SubmitButton disabled={isLoading || disabled} {...rest}>
      {children}
      {isLoading && (
        <CircularProgress color="secondary" style={{ marginLeft: "8px" }} />
      )}
    </SubmitButton>
  );
};

export const FormBackdrop = () => {
  const classes = useStylesBackdrop();
  const isLoading = useSelector(selectLoading);

  return (
    <Backdrop className={classes.root} open={isLoading}>
      <CircularProgress color="inherit" />
      <Typography variant="body1" style={{ color: "white" }}>
        Enviando proposta.
      </Typography>
      <Typography variant="body1" style={{ color: "white" }}>
        Pode levar alguns minutos!
      </Typography>
    </Backdrop>
  );
};

const useStylesBackdrop = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#000000cc",
  },
}));

export const EmailField = ({ errors, register, ...rest }) => {
  return (
    <FormField
      helperText={errors?.email?.message}
      inputRef={register}
      label="E-MAIL"
      name="email"
      uppercase={false}
      {...rest}
    />
  );
};

EmailField.fieldName = "email";
EmailField.schema = yup
  .string()
  .max(150, "O email deve ter menos de 150 caracteres")
  .test("email_test", "O email é inválido ou incompleto", (email) =>
    email === null || email === "" || validateEmail(email)
  );

  // Schema
export const stepOneSchema = yup.object().shape({
  [BirthdayField.fieldName]: BirthdayField.schema,
  [CellphoneField.fieldName]: CellphoneField.schema,
  [CpfField.fieldName]: CpfField.schema,
  [NameField.fieldName]: NameField.schema,
  [EmailField.fieldName]: EmailField.schema,
});

export const stepThreeSchema = yup.object().shape({
  [CidadeNascimentoField.fieldName]: CidadeNascimentoField.schema,
  [EstadoNascimentoField.fieldName]: EstadoNascimentoField.schema,
  [SexoField.fieldName]: SexoField.schema,
});

export const stepFourSchema = yup.object().shape({
  [BairroField.fieldName]: BairroField.schema,
  [CepField.fieldName]: CepField.schema,
  [CidadeField.fieldName]: CidadeField.schema,
  [ComplementoField.fieldName]: ComplementoField.schema,
  [EstadoField.fieldName]: EstadoField.schema,
  [NumeroField.fieldName]: NumeroField.schema,
  [RuaField.fieldName]: RuaField.schema,
});

export const rgSchema = yup.object().shape({
  [NomeMaeField.fieldName]: NomeMaeField.schema,
  [RgField.fieldName]: RgField.schema,
});

export const cnhSchema = yup.object().shape({
  [CnhField.fieldName]: CnhField.schema,
  [NomeMaeField.fieldName]: NomeMaeField.schema,
});