import clsx from "clsx";

import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  makeStyles,
} from "@material-ui/core";

const FormStepper = ({ activeStep, numOfSteps, ...rest }) => {
  const classes = useStylesFormStepper();
  const steps = Array(numOfSteps).fill("");

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      className={classes.stepper}
      connector={
        <StepConnector
          classes={{
            alternativeLabel: classes.alternativeLabel,
            line: classes.line,
          }}
        />
      }
      {...rest}
    >
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={FormStepIcon} />
        </Step>
      ))}
    </Stepper>
  );
};

const useStylesFormStepper = makeStyles((theme) => ({
  alternativeLabel: {
    left: "calc(-50% + 24px)",
    right: "calc(50% + 24px)",
    top: 24,
  },
  line: {
    border: "1px dashed rgba(255,255,255,0.5)",
    borderWidth: "0px 0px 2px 0px",
  },
  stepper: {
    background: "transparent",
  },
  [theme.breakpoints.up("sm")]: {
    alternativeLabel: {
      left: "calc(-50% + 32px)",
      right: "calc(50% + 32px)",
      top: 32,
    },
  },
}));

const FormStepIcon = ({ active, completed, icon }) => {
  const classes = useStylesFormStepIcon();

  return (
    <div
      className={clsx(classes.icon, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icon}
    </div>
  );
};

const useStylesFormStepIcon = makeStyles((theme) => ({
  icon: {
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.5)",
    borderRadius: "50%",
    color: theme.palette.text.secondary,
    display: "flex",
    fontSize: "24px",
    fontWeight: theme.typography.fontWeightLight,
    height: "48px",
    justifyContent: "center",
    width: "48px",
    zIndex: 2,
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    transition: theme.transitions.create(["background-color", "transform"], {
      duration: theme.transitions.duration.standard,
    }),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  [theme.breakpoints.up("sm")]: {
    icon: {
      width: "64px",
      height: "64px",
      fontSize: "28px",
    },
  },
}));

export default FormStepper;
