import { useCallback, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid } from "@material-ui/core";

import { urls } from "../utils/Constants";
import { resetSignup, storeCode, sendCpf } from "../store/SignupSlice";
import { useIsMobile } from "../utils/Hooks";

import { FullscreenLayout, FormLayout } from "../components/Layouts";
import { CodeMessage, MainHeader, SecureEnv } from "../components/MiscComponents";
import { AsyncSubmitButton, CpfField } from "../components/FormComponents";

const buttonText = "ABRA SUA CONTA";

const schema = yup.object().shape({
  [CpfField.fieldName]: CpfField.schema,
});

const Home = ({isConsultor}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { code } = useParams();

  // Reset stored data
  useEffect(() => {
    dispatch(resetSignup());
  }, [dispatch]);

  // Save code in case it is in the URL. Else save "00000"
  useEffect(() => {
    dispatch(storeCode({code, isConsultor}));
  }, [code, dispatch, isConsultor]);

  return isMobile ? (
    <FullscreenLayout>
      <MainHeader />
      <SecureEnv />
      <HomeForm isConsultor={isConsultor}/>
    </FullscreenLayout>
  ) : (
    <FullscreenLayout>
      <SecureEnv />
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        height="100%"
        overflow="hidden"
      >
        <Box width="45%" height="100%">
          <MainHeader />
        </Box>
        <Box width="65%" display="flex">
          <HomeForm
            isConsultor={isConsultor}
            layoutProps={{
              justifyContent: "center",
              margin: "10%",
            }}
          />
        </Box>
      </Box>
    </FullscreenLayout>
  );
};

const HomeForm = ({ layoutProps, isConsultor }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { code } = useParams();

  const { control, errors, handleSubmit, setError } = useForm({
    defaultValues: {
      cpf: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(sendCpf(formData?.cpf));

      if (sendCpf.fulfilled.match(result)) {
        history.push(urls.steps[1]);
      } else {
        setError(CpfField.fieldName, {
          type: "manual",
          message: result?.payload?.message,
        });
      }
    },
    [history, dispatch, setError]
  );

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)} {...layoutProps}>
      <Grid item xs={12}>
        <CpfField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <AsyncSubmitButton>{buttonText}</AsyncSubmitButton>
      </Grid>

      {isConsultor && 
        <Grid item xs={12}>
          <CodeMessage code={code}/>
        </Grid>
      }
    </FormLayout>
  );
};

export default Home;
