import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";

import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
} from "@material-ui/core";

import * as yup from "yup";
import { selectData, sendStepFive } from "../../store/SignupSlice";
import { useSnackbar } from "../../utils/Hooks";

import { FormLayout } from "../../components/Layouts";
import {
  AsyncSubmitButton,
  FormBackdrop,
} from "../../components/FormComponents";
import { ChatBubble, CodeMessage } from "../../components/MiscComponents";
import { yupResolver } from "@hookform/resolvers/yup";

// Texts
const buttonText = "PRÓXIMA ETAPA";
const serverErrorText = "Algum erro ocorreu no servidor!";

const StepFive = ({ onNext }) => {
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const data = useSelector(selectData);

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    defaultValues: {
      sms_check: false,
    },
    resolver: yupResolver(
      yup.object().shape({
        sms_check: yup
          .bool()
          .oneOf([true], "Você precisa aceitar as condições"),
      })
    ),
  });

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(sendStepFive(formData));

      if (sendStepFive.fulfilled.match(result)) {
        onNext();
      } else if (sendStepFive.rejected.match(result)) {
        const clientId = result?.payload?.response?.data?.message;

        if (clientId) {
          onNext();
        } else {
          showSnackbar(serverErrorText, "error");
        }
      }
    },
    [dispatch, onNext, showSnackbar]
  );

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <ChatBubble>
          Estamos quase lá! <br /> <br />
          Para validarmos o seu cadastro com o BMG e abrirmos a sua conta
          digital gratuita, precisamos que você acesse o link que enviamos para
          você via SMS e siga as instruções.
        </ChatBubble>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          leftPadding: "40px",
          leftMargin: "30px",
        }}
      >
        <FormControlLabel
          control={<Checkbox color="secondary" />}
          inputRef={register}
          name="sms_check"
          labelPlacement="end"
          style={{ color: "black", leftMargin: "4px", fontSize: "10px" }}
          label="Eu permito o envio de SMS / Whatsapp com as instruções para finalizar o cadastro!"
        />
        <FormHelperText
          style={{
            width: "100%",
            paddingLeft: "30px",
            textAlign: "left",
            color: "#f44336",
          }}
        >
          {errors.sms_check?.message}
        </FormHelperText>
      </Grid>

      <Grid item xs={12}>
        <AsyncSubmitButton>{buttonText}</AsyncSubmitButton>
      </Grid>

      <FormBackdrop />

      {data?.isConsultor && (
        <Grid item xs={12}>
          <CodeMessage code={data?.code} />
        </Grid>
      )}
    </FormLayout>
  );
};

export default StepFive;
