import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { ArrowBackIos as ArrowBackIosIcon } from "@material-ui/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import { useIsMobile, useIsSmallScreen } from "../utils/Hooks";

export const MainHeader = ({ ...rest }) => {
  const classes = useStylesMainHeader();

  const isMobile = useIsMobile();
  const isSmall = useIsSmallScreen();

  return (
    <Box
      alignItems={isMobile ? "center" : "flex-end"}
      className={classes.header}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      height={isMobile ? null : "100%"}
      {...rest}
    >
      <img
        src={isSmall ? "/images/intro.png" : "/images/intro@2x.png"}
        alt="Img"
        className={classes.image}
      />

      <Typography className={classes.headerText} variant="h5">
        Você está a um passo de libertar sua vida financeira!
      </Typography>
    </Box>
  );
};

const useStylesMainHeader = makeStyles((theme) => ({
  header: {
    background: "linear-gradient(90deg, rgb(206,79,0) 0%, rgb(255,121,6) 100%)",
    padding: theme.spacing(7),
  },
  headerText: {
    fontSize: "24px",
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "left",
  },
  [theme.breakpoints.up("md")]: {
    header: {
      boxShadow: theme.shadows[1],
    },
    headerText: {
      ...theme.typography.h5,
      fontWeight: theme.typography.fontWeightBold,
      marginTop: "15px",
      textAlign: "right",
    },
  },

  [theme.breakpoints.up("lg")]: {
    headerText: {
      ...theme.typography.h4,
      fontWeight: theme.typography.fontWeightBold,
      marginTop: "30px",
      textAlign: "right",
    },
    image: {
      height: "60vh",
    },
  },
}));

export const FinishHeader = ({ username, ...rest }) => {
  const classes = useStylesFinishHeader();
  const isMobile = useIsMobile();

  return (
    <Box
      alignItems="center"
      className={classes.header}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      {...rest}
    >
      {isMobile && (
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-start"
          alignItems="start"
        >
          <img
            src="/images/euquerograna.png"
            alt="euquerograna"
            style={{ width: "80px" }}
          />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
      >
        <Typography className={classes.headerText} variant="h5">
          Você está quase lá,
        </Typography>

        <Typography className={classes.usernameText} variant="h3">
          {username}!
        </Typography>

        {!isMobile && (
          <img
            src="/images/cartao_novo.png"
            alt="cartaonovo"
            className={classes.cardImg}
          />
        )}
      </Box>
    </Box>
  );
};

const useStylesFinishHeader = makeStyles((theme) => ({
  header: {
    background:
      "linear-gradient(90deg, rgba(243,108,1,1) 0%, rgba(255,133,22,1) 100%)",
    padding: theme.spacing(2, 5),
  },
  headerText: {
    fontSize: "24px",
    fontWeight: theme.typography.fontMedium,
    marginTop: theme.spacing(5),
  },
  usernameText: {
    fontSize: "32px",
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.up("md")]: {
    header: {
      background:
        "transparent linear-gradient(270deg, #FF7906 0%, #412E6E 100%) 0% 0% no-repeat padding-box",
      height: "100%",
      boxShadow: theme.shadows[1],
    },
    headerText: {
      ...theme.typography.h4,
    },
    usernameText: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  cardImg: {
    height: "60vh",
  },
  [theme.breakpoints.only("md")]: {
    headerText: {
      ...theme.typography.h5,
    },
    usernameText: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
}));

export const BackButton = ({ className, iconProps, isMobile, ...rest }) => {
  const classes = useStyleBackButton();

  return isMobile ? (
    <IconButton
      edge="start"
      color="secondary"
      className={classes.button}
      {...rest}
    >
      <ArrowBackIosIcon className={classes.icon} {...iconProps} />
    </IconButton>
  ) : (
    <Button
      color="secondary"
      startIcon={<ArrowBackIosIcon className={classes.icon} {...iconProps} />}
      {...rest}
    >
      <Typography variant="body1" className={classes.buttonText}>
        VOLTAR
      </Typography>
    </Button>
  );
};

const useStyleBackButton = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.primary,
  },
  buttonText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "24px",
  },
}));

export const SecureEnv = ({ ...rest }) => {
  const classes = useStylesSecureEnv();
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
      className={classes.secure}
      {...rest}
    >
      <FontAwesomeIcon icon={faLock} className={classes.icon} />
      <Typography variant="body2" align="center" className={classes.text}>
        Você está em um ambiente seguro
      </Typography>
    </Box>
  );
};

const useStylesSecureEnv = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(7),
    fontSize: "1.7rem",
  },
  secure: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(6, 1),
  },
  text: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  [theme.breakpoints.up("md")]: {
    icon: {
      fontSize: theme.typography.h4.fontSize,
    },
    text: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
}));

export const ChatBubble = ({ children }) => {
  const classes = useStylesChatBubble();

  return (
    <div className={classes.chatBubble}>
      <Typography variant="body1">{children}</Typography>
    </div>
  );
};

const useStylesChatBubble = makeStyles((theme) => ({
  chatBubble: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "30px",
    display: "inline-block",
    fontWeight: theme.typography.fontWeightMedium,
    height: "auto",
    minHeight: "70px",
    padding: theme.spacing(4),
    position: "relative",
    width: "calc(100% - 25px)",
    "&::after": {
      border: "27px solid",
      borderColor: `transparent transparent ${theme.palette.secondary.main} ${theme.palette.secondary.main}`,
      bottom: "0px",
      content: '""',
      height: 0,
      left: "auto",
      right: "-25px",
      position: "absolute",
      width: 0,
      zIndex: -1,
    },
  },
  [theme.breakpoints.up("md")]: {
    chatBubble: {
      width: "100%",
      padding: theme.spacing(7, 20),
      "&::after": { border: "0px" },
    },
  },
}));

export const CodeMessage = ({code}) => {
  const classes = useStylesCodeMessage();

  return (
    <Typography variant="body1" className={classes.message}>Código do Consultor: {code}</Typography>
  )
}

const useStylesCodeMessage = makeStyles((theme) => ({
  message: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "bold"
  }
}))
