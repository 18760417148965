import { useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

import { sendStepTwo, selectData } from "../../store/SignupSlice";
import { useIsMobile, useSnackbar } from "../../utils/Hooks";

import { FormLayout } from "../../components/Layouts";
import {
  AsyncSubmitButton,
  CnhField,
  cnhSchema,
  FormCircleButton,
  NomeMaeField,
  RgField,
  rgSchema,
} from "../../components/FormComponents";
import { ChatBubble, CodeMessage } from "../../components/MiscComponents";

// Texts
const buttonText = "PRÓXIMA ETAPA";
const chatBubbleText =
  "Agora, alguns dados sobre seu documento pessoal. Escolha o documento que esteja mais próximo de você:";
const serverErrorText = "Algum erro ocorreu no servidor!";

const StepTwo = ({ onNext }) => {
  const data = useSelector(selectData);
  const isMobile = useIsMobile();
  const [activeBtn, setActiveBtn] = useState(data?.step2?.btnSelected || 0);

  const handleSelectRg = useCallback(() => {
    if (activeBtn === 1) {
      setActiveBtn(0);
    }
  }, [activeBtn]);

  const handleSelectCnh = useCallback(() => {
    if (activeBtn === 0) {
      setActiveBtn(1);
    }
  }, [activeBtn]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      padding="24px"
      width="100%"
    >
      <ChatBubble>{chatBubbleText}</ChatBubble>

      <Box
        display="flex"
        justifyContent={!isMobile ? "space-around" : "center"}
        flexDirection={!isMobile ? "row" : "column"}
        alignItems="center"
        width="100%"
        marginTop={!isMobile ? "0px" : "20px"}
      >
        <Box
          display="flex"
          flexDirection={!isMobile ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
        >
          <FormCircleButton
            active={activeBtn === 0}
            icon={<FontAwesomeIcon icon={faIdCard} />}
            label="RG"
            onClick={handleSelectRg}
          />
          <FormCircleButton
            active={activeBtn === 1}
            icon={<FontAwesomeIcon icon={faIdCard} />}
            label="CNH"
            onClick={handleSelectCnh}
          />
        </Box>
        {activeBtn === 0 && (
          <Box display="flex" alignItems="flex-start" height="300px">
            <img
              src="/images/RG.png"
              alt="rg"
              style={{ width: !isMobile ? "300px" : "270px" }}
            />
          </Box>
        )}
        {activeBtn === 1 && (
          <Box height="300px">
            <img
              src="/images/cnh.png"
              alt="cnh"
              style={{ width: !isMobile ? "300px" : "270px" }}
            />
          </Box>
        )}
      </Box>

      {activeBtn === 0 && <RgForm onNext={onNext} />}
      {activeBtn === 1 && <CnhForm onNext={onNext} />}
    </Box>
  );
};

const RgForm = ({ onNext }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectData);
  const { showSnackbar } = useSnackbar();

  const { control, errors, handleSubmit, register } = useForm({
    defaultValues: {
      nome_mae: data?.step2?.nome_mae || "",
      rg: data?.step2?.rg || "",
    },
    mode: "onSubmit",
    resolver: yupResolver(rgSchema),
  });

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(sendStepTwo(formData));

      if (sendStepTwo.fulfilled.match(result)) {
        onNext();
      } else {
        showSnackbar(serverErrorText, "error");
      }
    },
    [dispatch, onNext, showSnackbar]
  );

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)} padding="0px">
      <Grid item xs={12}>
        <RgField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <NomeMaeField errors={errors} register={register} />
      </Grid>

      <Grid item xs={12}>
        <AsyncSubmitButton>{buttonText}</AsyncSubmitButton>
      </Grid>

      {data?.isConsultor && <Grid item xs={12}>
        <CodeMessage code={data?.code}/>
      </Grid>}
    </FormLayout>
  );
};

const CnhForm = ({ onNext }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectData);
  const { showSnackbar } = useSnackbar();

  const { control, errors, register, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues: {
      cnh: data.step2?.cnh || "",
      nome_mae: data.step2?.nome_mae || "",
    },
    resolver: yupResolver(cnhSchema),
  });

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(sendStepTwo(formData));

      if (sendStepTwo.fulfilled.match(result)) {
        onNext();
      } else {
        showSnackbar(serverErrorText, "error");
      }
    },
    [dispatch, onNext, showSnackbar]
  );

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)} padding="0px">
      <Grid item xs={12}>
        <CnhField control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <NomeMaeField errors={errors} register={register} />
      </Grid>

      <Grid item xs={12}>
        <AsyncSubmitButton>{buttonText}</AsyncSubmitButton>
      </Grid>

      {data?.isConsultor && <Grid item xs={12}>
        <CodeMessage code={data?.code}/>
      </Grid>}
    </FormLayout>
  );
};

export default StepTwo;
