import { Box } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeh, faSmile, faFrown } from "@fortawesome/free-regular-svg-icons";

import { FormIconButton } from "./FormComponents";

const RatingComponent = ({ active, setActive }) => {
  return (
    <Box display="flex" justifyContent="center">
      <FormIconButton
        active={active === 1}
        activeStyle={{ color: "red" }}
        icon={<FontAwesomeIcon icon={faFrown} style={{ fontSize: "60px" }} />}
        onClick={setActive.bind(this, 1)}
      />

      <FormIconButton
        active={active === 2}
        activeStyle={{ color: "#FFBB00" }}
        icon={<FontAwesomeIcon icon={faMeh} style={{ fontSize: "60px" }} />}
        onClick={setActive.bind(this, 2)}
      />

      <FormIconButton
        active={active === 3}
        activeStyle={{ color: "#5CFB86" }}
        icon={<FontAwesomeIcon icon={faSmile} style={{ fontSize: "60px" }} />}
        onClick={setActive.bind(this, 3)}
      />
    </Box>
  );
};

export default RatingComponent;
