class Formatter {
  numbersOnlyCpf(cpf) {
    return cpf?.replace(/\./g, "").replace("-", "");
  }

  numbersOnlyRg(rg) {
    return rg?.replace(/\./g, "").replace("-", "");
  }

  numbersOnlyCep(cep) {
    return cep?.replace("-", "");
  }

  uf(estado) {
    return estado?.substring(estado.length - 3, estado.length - 1);
  }

  onlyDdd(celular) {
    return celular?.substring(1, 3);
  }

  onlyPhoneNumber(celular) {
    return celular?.substring(5).replace("-", "");
  }

  oneLetterSexo(sexo) {
    switch (sexo?.toLowerCase()) {
      case "feminino":
        return "F";
      case "masculino":
        return "M";
      case "não informado":
        return "I";
      default:
        return "I";
    }
  }

  dateToApi(data) {
    let year = data?.substring(6, 11);
    let month = data?.substring(3, 5);
    let day = data?.substring(0, 2);

    return year + "-" + month + "-" + day;
  }

  nomeToApi(nome) {
    return nome.trim().toUpperCase();
  }
}

export default new Formatter();
