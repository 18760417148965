import api from "./Api";
import Formatter from "../utils/Formatter";

const client_temp = "cadastro/clientes-temp/";
const client_send = "cadastro/clientes-send";

class SignupService {
  async checkCpfExistance(cpf) {
    try {
      const fCpf = Formatter.numbersOnlyCpf(cpf);
      return await api.get(client_temp + fCpf);
    } catch (error) {
      throw error;
    }
  }

  async checkIwmCpfExistance(cpf) {
    try {
      const fCpf = Formatter.numbersOnlyCpf(cpf);
      return await api.get("/cadastro/verifica/proposta/" + fCpf);
    } catch (error) {
      throw error;
    }
  }


  async sendStepOne(code, stepOneData, currentClient) {
    const cpf = Formatter.numbersOnlyCpf(stepOneData.cpf);
    const ddd = Formatter.onlyDdd(stepOneData.celular);
    const numero = Formatter.onlyPhoneNumber(stepOneData.celular);
    const dataNascimento = Formatter.dateToApi(stepOneData.data_nascimento);
    const nome = Formatter.nomeToApi(stepOneData.nome);
    const email = stepOneData.email;

    let body = {
      celular: {
        ddd: ddd,
        numero: numero,
      },
      cidadeNascimento: "",
      codigo: code,
      cpf: cpf,
      dataNascimento: dataNascimento,
      email: email,
      endereco: {},
      identidade: {},
      nome: nome,
      nomeMae: "",
      sexo: "",
      robo: null,
      ufNascimento: "",
    };

    try {
      if (currentClient?.id) {
        const response = await api.put(client_temp + currentClient.id, body);
        return response;
      } else {
        const response = await api.post(client_temp, body);
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  async sendStepTwo(code, stepTwoData, client) {
    const rg = Formatter.numbersOnlyRg(stepTwoData.rg);
    const nomeMae = Formatter.nomeToApi(stepTwoData.nome_mae);

    let body = {
      ...client,
      identidade: {
        ...client.identidade,
        emissor: "ssp",
        numero: rg || stepTwoData.cnh,
      },
      nomeMae: nomeMae,
      codigo: code,
    };

    try {
      const response = await api.put(client_temp + client.id, body);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendStepThree(code, stepThreeData, client) {
    const sexo = Formatter.oneLetterSexo(stepThreeData.sexo);

    let body = {
      ...client,
      cidadeNascimento: stepThreeData.cidade_nascimento.toUpperCase(),
      codigo: code,
      identidade: {
        ...client.identidade,
        uf: stepThreeData.estado_nascimento,
      },
      sexo: sexo,
      ufNascimento: stepThreeData.estado_nascimento,
    };

    try {
      const response = await api.put(client_temp + client.id, body);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendStepFour(code, stepFourData, client) {
    const cep = Formatter.numbersOnlyCep(stepFourData.cep);

    let body = {
      ...client,
      endereco: {
        bairro: stepFourData.bairro.toUpperCase(),
        cep: cep,
        cidade: stepFourData.cidade.toUpperCase(),
        logradouro: stepFourData.rua.toUpperCase(),
        complemento: stepFourData.complemento.toUpperCase(),
        numero: stepFourData.numero,
        uf: stepFourData.estado,
      },
      codigo: code,
    };

    try {
      const response = await api.put(client_temp + client.id, body);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async finishAccountCreation(code, client) {
    const sessionData = JSON.parse(sessionStorage.getItem("signup_data"));

    let body = {
      ...client,
      codigo: sessionData.code,
    };

    try {
      const response = await api.post(client_send, body);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async sendFeedback(clientId, sms, comment, rating = 0) {
    let body = {
      cliente: clientId,
      comentario: comment,
      nota: parseInt(rating),
      sms: sms ? 1 : 0,
    };

    try {
      const response = await api.post("cadastro/feedback/", body);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new SignupService();
