import clsx from "clsx";

import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  makeStyles,
  Box,
  withStyles,
  Typography,
} from "@material-ui/core";

import { Check } from "@material-ui/icons";

export const StepperWithContainer = () => {
  const classes = useStylesStepperWithContainer();
  const steps = [
    "Cadastro EuQueroGrana",
    "Encaminhada para análise",
    "Aprovação em até 48h",
    "Entrega do Cartão",
  ];

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Stepper
        alternativeLabel
        activeStep={1}
        connector={<QontoConnector />}
        className={classes.stepper}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <Typography variant="body2" className={classes.label}>
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const useStylesStepperWithContainer = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(6, 1),
  },
  label: {
    fontSize: "9px",
    color: "#fff",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.up("md")]: {
    container: {
      backgroundColor: "transparent",
      width: "100%",
    },
    label: {
      color: theme.palette.secondary.main,
      fontSize: "15px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  line: {
    borderColor: "#fff",
    borderTopWidth: 3,
    borderRadius: 1,
  },
  [theme.breakpoints.up("md")]: {
    line: {
      borderColor: theme.palette.secondary.main,
    },
  },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    display: "flex",
    height: 22,
    alignItems: "center",
  },

  circle: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: theme.palette.success.main,
    zIndex: 1,
    fontSize: 35,
  },
  [theme.breakpoints.up("md")]: {
    root: {
      color: theme.palette.secondary.main,
    },
    circle: {
      width: 16,
      height: 16,
    },
    completed: {
      fontSize: 40,
    },
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}
