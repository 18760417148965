import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { FormButton } from "./FormComponents";

const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={open}
      scroll="paper"
      classes={{ paper: classes.paper }}
      disableBackdropClick
    >
      <DialogTitle color="primary" className={classes.title}>
        Parabéns!
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          Agora você verá um vídeo explicando como formalizar sua conta!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormButton onClick={onConfirm}>Assistir</FormButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "30px",
  },
  title: {
    color: theme.palette.secondary.main,

    "& h2": {
      fontSize: "22px",
      fontWeight: "600",
      textAlign: "center",
    },
  },
  contentText: {
    color: theme.palette.secondary.main,
  },
  btn: {},
}));

export default ConfirmationDialog;
