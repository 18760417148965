import { useCallback, useState } from "react";

import cep from "cep-promise";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import { selectData, sendStepFour } from "../../store/SignupSlice";
import { useSnackbar } from "../../utils/Hooks";

import { FormLayout } from "../../components/Layouts";
import {
  CepField,
  ComplementoField,
  NumeroField,
  RuaField,
  BairroField,
  CidadeField,
  EstadoField,
  AsyncSubmitButton,
  stepFourSchema
} from "../../components/FormComponents";
import { ChatBubble, CodeMessage } from "../../components/MiscComponents";

// Texts
const buttonText = "PRÓXIMA ETAPA";
const chatBubbleText =
  "Precisamos também de algumas informações sobre o seu endereço pessoal, e então finalizaremos o cadastro!";
const serverErrorText = "Algum erro ocorreu no servidor!";

const StepFour = ({ onNext }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectData);
  const { showSnackbar } = useSnackbar();

  const { control, errors, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      bairro: data?.step4?.bairro || "",
      cep: data?.step4?.cep || "",
      cidade: data?.step4?.cidade || "",
      complemento: data?.step4?.complemento || "",
      estado: data?.step4?.estado || "",
      numero: data?.step4?.numero || "",
      rua: data?.step4?.rua || "",
    },
    mode: "onSubmit",
    resolver: yupResolver(stepFourSchema),
  });

  // For cep service
  const [cepLoading, setCepLoading] = useState(false);
  const wAddress = watch(["rua", "bairro", "cidade", "estado"]);

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(sendStepFour(formData));

      if (sendStepFour.fulfilled.match(result)) {
        onNext();
      } else {
        showSnackbar(serverErrorText, "error");
      }
    },
    [dispatch, onNext, showSnackbar]
  );

  const handleOnBlurCep = useCallback(
    (e) => {
      if (e?.target?.value?.length === 9) {
        setCepLoading(true);
        cep(e.target.value)
          .then((address) => {
            setCepLoading(false);
            setValue("rua", address.street);
            setValue("bairro", address.neighborhood);
            setValue("cidade", address.city);
            setValue("estado", address.state);
          })
          .catch(() => {
            setCepLoading(false);
          });
      }
    },
    [setValue]
  );

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <ChatBubble>{chatBubbleText}</ChatBubble>
      </Grid>

      <Grid item xs={12}>
        <CepField
          control={control}
          customOnBlur={handleOnBlurCep}
          disabled={cepLoading}
          errors={errors}
          loading={cepLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <RuaField
          disabled={cepLoading}
          errors={errors}
          register={register}
          shrink={wAddress.rua ? true : undefined}
        />
      </Grid>

      <Grid item xs={12}>
        <NumeroField disabled={cepLoading} control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <ComplementoField
          disabled={cepLoading}
          errors={errors}
          register={register}
        />
      </Grid>

      <Grid item xs={12}>
        <BairroField
          disabled={cepLoading}
          errors={errors}
          register={register}
          shrink={wAddress.bairro ? true : undefined}
        />
      </Grid>

      <Grid item xs={12}>
        <EstadoField
          disabled={cepLoading}
          errors={errors}
          onChange={() => {
            setValue("cidade", "");
          }}
          register={register}
          shrink={wAddress.estado ? true : undefined}
        />
      </Grid>

      <Grid item xs={12}>
        <CidadeField
          control={control}
          disabled={cepLoading}
          errors={errors}
          estado={wAddress?.estado}
          shrink={wAddress?.cidade ? true : undefined}
        />
      </Grid>

      <Grid item xs={12}>
        <AsyncSubmitButton disabled={cepLoading}>
          {buttonText}
        </AsyncSubmitButton>
      </Grid>

      {data?.isConsultor && <Grid item xs={12}>
        <CodeMessage code={data?.code}/>
      </Grid>}
    </FormLayout>
  );
};

export default StepFour;
