import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

import signupReducer from "./SignupSlice";

const store = configureStore({
  reducer: {
    signup: signupReducer,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== "production"
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware(),
});

export default store;
