import { useCallback, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid, Typography } from "@material-ui/core";

import { urls } from "../utils/Constants";
import { sendFeedback, selectData } from "../store/SignupSlice";
import { useSnackbar } from "../utils/Hooks";

import { FormLayout, FullscreenLayout } from "../components/Layouts";
import { AsyncSubmitButton, MessageField } from "../components/FormComponents";
import {
  CodeMessage,
  FinishHeader,
  SecureEnv,
} from "../components/MiscComponents";
import RatingComponent from "../components/RatingComponent";
import { StepperWithContainer } from "../components/FinishStepper";
import InfoExampleDialog from "../components/InfoExampleDialog";
import { useIsMobile } from "../utils/Hooks";
import Redirecter from "../components/Redirecter";
import VideoDialog from "../components/VideoDialog";

import { faIdCard, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Schema
const schema = yup.object().shape({
  [MessageField.fieldName]: MessageField.schema,
  rating: yup.string(),
});

// Texts
const buttonText = "FINALIZAR";
const successText = "Obrigado pela avaliação!";
const serverErrorText = "Algum erro ocorreu no servidor!";

const Finish = () => {
  const data = useSelector(selectData);
  const isMobile = useIsMobile();

  const username = data?.nome?.substring(0, data?.nome?.indexOf(" "));

  return (
    <Redirecter step="/smsinfor">
      <FullscreenLayout>
        {isMobile ? (
          <Box>
            <FinishHeader username={username} />
            <StepperWithContainer />
            <FeedbackForm />
          </Box>
        ) : (
          <div>
            <SecureEnv />
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              height="100%"
              style={{ overflow: "hidden" }}
            >
              <Box width="45%" height="100%">
                <FinishHeader
                  username={username}
                  style={{ overflow: "hidden" }}
                />
              </Box>
              <Box
                width="65%"
                display="flex"
                flexDirection="column"
                style={{
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  overflow: "auto",
                }}
              >
                <StepperWithContainer />
                <FeedbackForm />
              </Box>
            </Box>
          </div>
        )}
      </FullscreenLayout>
    </Redirecter>
  );
};

const FeedbackForm = ({ layoutProps }) => {
  const data = useSelector(selectData);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const { showSnackbar } = useSnackbar();

  const [_, setActiveRating] = useState();
  const { register, errors, getValues, handleSubmit, setValue } = useForm({
    defaultValues: {
      message: data?.finish?.message || "",
    },
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!getValues("rating")) {
      register("rating");
      setValue("rating", 0);
    }
  }, [register, setValue, getValues]);

  const onSubmit = useCallback(
    async (formData) => {
      const result = await dispatch(sendFeedback(formData));

      if (sendFeedback.fulfilled.match(result)) {
        if (data.isConsultor) {
          history.push(urls.homeConsultor + data.code);
        } else {
          history.push(urls.homeWithCode + data.code);
        }

        showSnackbar(successText, "success");
      } else {
        showSnackbar(serverErrorText, "error");
      }
    },
    [dispatch, history, showSnackbar, data.code]
  );

  // Info dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  // Video dialog
  const [videoOpen, setVideoOpen] = useState(
    !JSON.parse(sessionStorage.getItem("signup_data")).hasConfirmedVideo
  );

  const handleOpenVideo = useCallback(() => {
    setVideoOpen(true);
  }, []);

  const handleCloseVideo = useCallback(() => {
    setVideoOpen(false);
  }, []);

  const handleChangeActiveRating = useCallback(
    (rating) => {
      setActiveRating(rating);
      setValue("rating", rating);
    },
    [setValue]
  );

  return (
    <div>
      <FormLayout onSubmit={handleSubmit(onSubmit)} {...layoutProps}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="body1" color="secondary">
              <b>Dica importante!</b> Para ter agilidade na aprovação da sua
              proposta, tenha em mãos seu RG ou CNH, pois será necessário
              encaminhar a foto.
            </Typography>
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent={isMobile ? "center" : "space-between"}
              alignItems="center"
              marginTop="12px"
            >
              <Typography
                variant="body1"
                color="secondary"
                onClick={handleOpenVideo}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: isMobile ? "12px" : "0px",
                }}
              >
                <FontAwesomeIcon
                  icon={faVideo}
                  style={{ marginRight: "4px" }}
                />
                Veja como formalizar sua conta!
              </Typography>

              <Typography
                variant="body1"
                color="secondary"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                onClick={() => {
                  handleOpenDialog();
                }}
              >
                <FontAwesomeIcon
                  icon={faIdCard}
                  style={{ marginRight: "4px" }}
                />
                Clique aqui para verificar o formato correto.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" color="secondary" align="center">
            <b>Como está sendo sua experiência até aqui?</b>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ color: "black", display: "flex", justifyContent: "center" }}
        >
          <RatingComponent
            active={getValues("rating")}
            setActive={handleChangeActiveRating}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" color="secondary" align="center">
            <b>Nos deixe uma opinião!</b>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <MessageField errors={errors} register={register} />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "30px" }}>
          <Box display="flex" justifyContent="center">
            <AsyncSubmitButton>{buttonText}</AsyncSubmitButton>
          </Box>
        </Grid>

        {data?.isConsultor && (
          <Grid item xs={12}>
            <CodeMessage code={data?.code} />
          </Grid>
        )}

        <Grid
          container
          spacing={1}
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
            marginTop: "auto",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="body1" color="secondary">
              <b>Central de Relacionamento</b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" color="secondary">
              <b>BMG Card</b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" color="secondary">
              40027007
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" color="secondary">
              0800 770 1790
            </Typography>
          </Grid>
        </Grid>
      </FormLayout>

      {!data.isConsultor && (
        <VideoDialog open={videoOpen} confirmation onClose={handleCloseVideo} />
      )}
      <InfoExampleDialog open={dialogOpen} onClose={handleCloseDialog} />
    </div>
  );
};

export default Finish;
